<template>
    <div class="about-page">
        <van-nav-bar :border="false" :placeholder="true" :fixed="true" :title="$t('充值说明')" left-arrow @click-left="onClickLeft">
        </van-nav-bar>
        <div class="wrapper" style="font-size: 14px;" v-html="html">
        </div>
    </div>
</template>

<script>
    export default {
        components: {},
        data() {
            return {
                name: '',
                html: '',
            }
        },
        methods: {
            onClickLeft() {
                this.$router.go(-1)
            },
            getData() {
                this.$axios
                    .get('/help/get_content?type=14')
                    .then((res) => {
                        this.html = res.data;
                    });
            },
        },
		mounted() {
			this.getData();
		}

    }
</script>
<style scoped>
    .wrapper{
        line-height: 2;
    }
</style>